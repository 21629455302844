import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { useEvent } from "../../hooks/useEvent";

import Button from "../atoms/Button";
import Headline from "../atoms/Headline";

import ContentWrapper from "../molecules/ContentWrapper";

interface MatchParams {
  eventId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const Schedule = ({ match }: Props) => {
  const { eventId } = match.params;
  const { error, data, loading, refetch, isConnected } = useEvent(eventId);

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };
  return (
    <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
      {data && (
        <>
          <Headline title="Schedule" icon="ListUl" />
          {data.entry.children.map((entry: any) => {
            if (entry.typeHandle === "day") {
              let day = new Date(entry.startDate).toLocaleDateString("en-us", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });

              return (
                <div key={entry.id}>

                  <Button
                    to={"/events/" +
                      eventId + "/details/day/" +
                      entry.id}
                    tag={day}
                    text={entry.title}
                  />

                </div>
              );
            }
            return "";
          })}
        </>
      )}

    </ContentWrapper>
  );
};

export default Schedule;
