import React from "react";

import { useEvent } from "../../../hooks/useEvent";

import Button from "../../atoms/Button";
import Headline from "../../atoms/Headline";
import Subhead from "../../atoms/Subhead";

import DateString from "../../utilities/DateString";
import timeString from "../../utilities/TimeString";

import * as icons from "../../icons"
import ContentWrapper from "../../molecules/ContentWrapper";

interface EventData {
  id: string;
  title: string;
  parent: any;
  shortname?: string;
  startTime?: string;
  endTime?: string;
  venue?: string;
  image?: {
    srcUrl: string;
  }[]
}

interface EventListProps {
  eventId: string;
  headline: string;
  icon: keyof typeof icons;
  eventKey: string;
  buttonPath: string;
  tagData: keyof EventData;
}

const EventList: React.FC<EventListProps> = ({ eventId, headline, icon, eventKey, buttonPath, tagData }) => {
  const { error, data, loading, refetch, isConnected } = useEvent(eventId);

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  let currentTime: string = "";
  let showTime: boolean = false;

  return (
    <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
      {data && (
        <>
          <Headline title={headline} icon={icon} />

          {data[eventKey].map((entry: EventData) => {
            let eventInfo: any = {};

            eventInfo.day =
              entry.parent?.startDate ?? entry.parent?.parent?.startDate ?? false;

            eventInfo.startTime =
              entry.startTime ?? entry.parent.startTime ?? false;
            eventInfo.endTime =
              entry.endTime ?? entry.parent.endTime ?? false;

            if (eventInfo.startTime !== currentTime) {
              currentTime = eventInfo.startTime;
              showTime = true;
            } else {
              showTime = false;
            }

            return (
              <div key={entry.id} className="">
                {showTime && (
                  <>
                    <span className="block h-6"></span>
                    <Subhead
                      title={DateString(eventInfo.day)}
                      tag={timeString(eventInfo.startTime, eventInfo.endTime)}
                    />
                  </>
                )}

                <Button
                  to={`/events/${eventId}/details/${buttonPath}/${entry.id}`}
                  text={entry.title}
                  tag={entry[tagData]}
                />
              </div>
            );
          })}
        </>
      )}
    </ContentWrapper>
  );
};

export default EventList;
