import React from "react";
import './TextBlock.scss'

type TextBlockProps = {
  text: {
    __html: string;
  };
};

const TextBlock = ({ text: { __html = "" } }: TextBlockProps) => {
  return (
    <div className="text-block">
      <div dangerouslySetInnerHTML={{ __html }}></div>
    </div>
  );
};

export default TextBlock;
