import {gql, useLazyQuery} from "@apollo/client";
import {useEffect} from "react";
import {MOBILE_PAGE_CONTENT_FRAGMENT} from "./MobilePageContentFragment";
import { useNetworkStatus } from "./useNetworkStatus";

const GET_DETAILS = gql`
  query GetDetails( $id: [QueryArgument] ) {
    entry(site: "texasbaptists", id: $id, type: "details" ) {
      title
      id
      ${MOBILE_PAGE_CONTENT_FRAGMENT} 
    }
  }
`;

interface DetailsResult {
  error: any;
  data: any;
  loading: boolean;
  refetch: () => void;
  isConnected: boolean;
}

export const useDetails = (id: string | null): DetailsResult => {
  const [getDetails, { error, data, loading }] = useLazyQuery(GET_DETAILS);
  const isConnected = useNetworkStatus();

  useEffect(() => {
    if (id && isConnected) {
      getDetails({ variables: { id } }).catch(error => console.error(error));
    }
  }, [getDetails, id, isConnected]);

  const refetch = () => {
    if (id && isConnected) {
      getDetails({ variables: { id }, fetchPolicy: "network-only" }).catch(error => console.error(error));
    }
  };

  return { error, data, loading, refetch, isConnected };
};
