import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import Event from "./components/templates/Event";
import Events from "./components/templates/Events";
import Article from "./components/templates/Article";
import News from "./components/templates/News";
import Icon from "./components/atoms/Icon";
import UpdateNotification from "./components/templates/UpdateNotification";

import {Browser} from "@capacitor/browser";

import {
  IonApp,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';

import { IonReactRouter } from '@ionic/react-router';

import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './theme/variables.css';
import './app.scss';
setupIonicReact();

const App: React.FC = () => {

  const client = new ApolloClient({
    uri: process.env.REACT_APP_API_ENDPOINT,
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <IonApp>
        <UpdateNotification />
        <IonReactRouter>
          <IonTabs>
              <IonRouterOutlet>
                <Route exact path="/events" component={Events} />
                <Route path="/events/:eventId" component={Event} />
                <Route exact path="/news" component={News} />
                <Route path="/news/:articleId" component={Article} />
                <Route exact path="/">
                  <Redirect to="/events" />
                </Route>
              </IonRouterOutlet>

            <IonTabBar slot="bottom" style={{"--background":"var(--ion-tab-bar-background-color)"}} className="border-neutral-200 dark:border-neutral-900 border-t">
              <IonTabButton tab="events" href="/events">
                <Icon icon="Calendars" className="h-6 w-6 mt-2 fill-current"/>
                <div className="mb-1">Events</div>
              </IonTabButton>
              <IonTabButton tab="news" href="/news">
                <Icon icon="Newspaper" className="h-6 w-6  mt-2 fill-current"/>
                <div className="mb-1">News</div>
              </IonTabButton>
              <IonTabButton tab="about" onClick={() => Browser.open({ url: 'https://www.texasbaptists.org/about' })}>
                <Icon icon="Logo" className="h-6 w-6 mt-2 fill-current" />
                <div className="mb-1">About</div>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </IonApp>
    </ApolloProvider>
  );
};

export default App;

