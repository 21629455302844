import { useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useNetworkStatus } from "./useNetworkStatus";

const GET_NEWS = gql`
    query GetNews {
        entries(site: "texasbaptists", type: "articlev1", limit: 20) {
            title
            id
            teaser
            articleAuthor
            featuredImage {
                srcUrl: url @transform(width: 1600, height: 900, mode: "crop")
            }
        }
    }
`;

interface NewsResult {
  error: any;
  data: any;
  loading: boolean;
  refetch: () => void;
  isConnected: boolean;
}

export const useNews = (): NewsResult => {
  const [getNews, { error, data, loading }] = useLazyQuery(GET_NEWS);
  const isConnected = useNetworkStatus();

  useEffect(() => {
    if (isConnected) {
      getNews().catch(error => console.error(error));
    }
  }, [getNews, isConnected]);

  const refetch = () => {
    if (isConnected) {
      getNews({ fetchPolicy: "network-only" }).catch(error => console.error);
    }
  };

  return { error, data, loading, refetch, isConnected };
};
