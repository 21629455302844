import React from "react";
import { IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import Button from "../atoms/Button";
import ContentWrapper from "../molecules/ContentWrapper";
import { useNews } from "../../hooks/useNews";
import { Browser } from "@capacitor/browser";

interface Entry {
  id: string;
  title: string;
  articleAuthor: string;
  teaser?: string;
  featuredImage?: {
    srcUrl: string;
  }[];
}

const News: React.FC<RouteComponentProps> = () => {
  const { error, data, loading, refetch, isConnected } = useNews();

  const handleRefresh = async (article: CustomEvent) => {
    await refetch();
    article.detail.complete();
  }

  return (
    <IonPage>
      <IonHeader translucent className="ion-no-border">
        <IonToolbar style={{"--background":"var(--ion-tab-bar-background-color"}} className="bg-opacity-50">
          <IonTitle>News</IonTitle>
        </IonToolbar>
      </IonHeader>
      <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
        {data && (
          <>
            {data.entries.map((article: Entry) => (
              <React.Fragment key={article.id}>
                <>
                  {article.featuredImage &&
                      <Button to={`/news/${article.id}`} text={article.title} tag={article.articleAuthor} image={article.featuredImage[0]} AspectVideo />
                  }
                  {!article.featuredImage &&
                      <Button to={`/news/${article.id}`} text={article.title} tag={article.articleAuthor} fallback='Newspaper' AspectVideo />
                  }
                </>
              </React.Fragment>
            ))}
            <React.Fragment>
              <Button text={"See More"} icon={"ArrowUpRightFromSquare"} onClick={() => Browser.open({ url: 'https://www.texasbaptists.org/category/news?utm_source=txb app&utm_medium=see more&utm_campaign=news' })} />
            </React.Fragment>
          </>
        )}
      </ContentWrapper>
    </IonPage>
  );
};

export default News;
