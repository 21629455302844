import React from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../atoms/Icon";
import * as icons from "../../icons";

interface TopNavButtonProps {
  icon: keyof typeof icons;
  route?: string;
}

const TopNavButton = ({ icon, route }: TopNavButtonProps) => {
  const history = useHistory();

  const handleClick = () => {
    if (route) {
      history.push(route);
    } else {
      history.goBack();
    }
  };

  return (
    <button
      onClick={handleClick}
    >
      <Icon
        icon={icon}
        className="h-5 fill-primary px-4" />
    </button>
  );
};

export default TopNavButton;
