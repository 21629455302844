import React from 'react';
import {IonContent, IonRefresher, IonRefresherContent} from "@ionic/react";
import Error from "../../atoms/Error";
import Loading from "../../atoms/Loading";
import Icon from "../../atoms/Icon";

interface ContentWrapperProps {
  children: React.ReactNode;
  handleRefresh?: any;
  isConnected?: boolean;
  error?: any;
  loading?: boolean;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ children, handleRefresh, isConnected, error, loading }) => {
  return (
    <IonContent >
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent/>
      </IonRefresher>
      <div className="">
        <div className="safe-sides content-wrapper pb-20">
          {!isConnected && (
            <div className="bg-neutral-500 bg-opacity-10 mb-6 text-xs p-4 flex gap-2">
              <Icon icon={"TriangleExclamation"} className="fill-neutral-500 opacity-50 h-4 inline-block" />
              <p>You are currently offline.</p>
            </div>
          )}
          {error && <Error errorData={error} />}
          {loading && <Loading />}
          {children}
        </div>
      </div>
    </IonContent>
  );
};

export default ContentWrapper;
