import React from "react";

const Error = ({...props}) => {
  return(
    <div color="danger">
      <div>
        Error: {props.errorData.message ?? "Something went wrong."}
      </div>
    </div>
  )
}

export default Error;
