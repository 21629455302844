import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";

import Schedule from "./Schedule";
import Day from "./Day";
import Meals from "./Meals";
import Speakers from "./Speakers";
import Workshops from "./Workshops";
import Worship from "./Worship";
import Meal from "./Meal";
import Person from "./Person";
import Session from "./Session";
import Workshop from "./Workshop";
import Activity from "./Activity";
import Details from "./Details";

import TopNavButton from "../atoms/TopNavButton";
import {IonButtons, IonHeader, IonRouterOutlet, IonToolbar, IonButton, IonContent} from "@ionic/react";

interface MatchParams {
  eventId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const EventDetails: React.FC<Props> = ({ match, history }) => {
  const eventId = match.params.eventId;

  return (
    <>
      <IonHeader className="ion-no-border border-b dark:border-neutral-800 bg-white">
        <IonToolbar style={{"--background":"var(--ion-tab-bar-background-color)"}} className="bg-opacity-50">
          <IonButtons slot="start">
            <IonButton>
              <TopNavButton icon="ChevronLeft" />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton>
              <TopNavButton icon="CalendarLines" route={`/events/${eventId}`} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRouterOutlet>
          <Route exact path="/events/:eventId/details/schedule" component={Schedule} />
          <Route exact path="/events/:eventId/details/meals" component={Meals} />
          <Route exact path="/events/:eventId/details/speakers" component={Speakers} />
          <Route exact path="/events/:eventId/details/workshops" component={Workshops} />
          <Route exact path="/events/:eventId/details/worship" component={Worship} />
          <Route exact path="/events/:eventId/details/activity/:entryId" component={Activity} />
          <Route exact path="/events/:eventId/details/day/:entryId" component={Day} />
          <Route exact path="/events/:eventId/details/mealReception/:entryId" component={Meal} />
          <Route exact path="/events/:eventId/details/person/:entryId" component={Person} />
          <Route exact path="/events/:eventId/details/session/:entryId" component={Session} />
          <Route exact path="/events/:eventId/details/workshop/:entryId" component={Workshop} />
          <Route exact path="/events/:eventId/details/details/:entryId" component={Details} />
        </IonRouterOutlet>
      </IonContent>

    </>
  );
};

export default EventDetails;
