import React from "react";

type ImageProps = {
  url: string;
  alt?: string;
};

const Image: React.FC<ImageProps> = ({ alt, url }) => {
  return (
    <img
      src={url}
      className="text-3xl font-bold mb-6 rounded-md shadow"
      alt={alt}
    />
  );
};

export default Image;
