import { useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { MOBILE_PAGE_CONTENT_FRAGMENT } from "./MobilePageContentFragment";
import { useNetworkStatus } from "./useNetworkStatus";

const GET_EVENT = gql`
  query GetEvent($id: [QueryArgument], $idInt: Int) {
    entry(site: "texasbaptists", id: $id, type: "event") {
      title
      id
      image {
        url @transform(width: 1600, height: 900, mode: "crop")
      }
      primary
      locationOther
      children(type: "day") {
        title
        id
        typeHandle
        ... on schedule_day_Entry {
          startDate
        }
      }
      speakers: people {
        id 
        title
        ... on people_staff_Entry {
          fullName
        }
        profilePhoto {
          url @transform(width: 1600, height: 1600, mode: "crop")
        }
      }
      worship: contact {
        id 
        title
        ... on people_staff_Entry {
          fullName
        }
        profilePhoto {
          url @transform(width: 1600, height: 1600, mode: "crop")
        }
      }
      ${MOBILE_PAGE_CONTENT_FRAGMENT}
    }
    workshops: entries( site: "texasbaptists", type: "workshop", descendantOf: $idInt ) {
      ... on schedule_workshop_Entry {
        title
        id
        shortname
        parent {
          ... on schedule_activity_Entry {
            id
            startTime
            endTime
            parent {
              ...on schedule_day_Entry {
                id
                startDate
              }
            }
          }
        }
      }
    }
    meals: entries( site: "texasbaptists", type: "mealReception", descendantOf: $idInt ) {
      ... on schedule_mealReception_Entry {
        title
        id
        startTime
        endTime
        venue
        parent {
          ... on schedule_activity_Entry {
            id
            startTime
            endTime
            parent {
              ...on schedule_day_Entry {
                id
                startDate
              }
            }
          }
          ... on schedule_day_Entry {
            id
            startDate
          }
        }
      }
    }
    registration: entries( site: "texasbaptists", type: "day", descendantOf: $idInt ) {
      id
      startDate
      children {
        ... on schedule_registration_Entry {
          typeHandle
          startTime
          endTime
          id
        }
      }
    }
    exhibits: entries( site: "texasbaptists", type: "day", descendantOf: $idInt ) {
      id
      startDate
      children {
        ... on schedule_exhibitHall_Entry {
          typeHandle
          startTime
          endTime
          id
        }
      }
    }
  }
`;

interface EventResult {
  error: any;
  data: any;
  loading: boolean;
  refetch: () => void;
  isConnected: boolean;
}
export const useEvent = (idString: string): EventResult => {
  const id = parseInt(idString);
  const [getEvent, { error, data, loading }] = useLazyQuery(GET_EVENT);
  const isConnected = useNetworkStatus();

  useEffect(() => {
    if (id && isConnected) {
      getEvent({ variables: { id, idInt: id } }).catch(error => console.error(error));
    }
  }, [getEvent, id, isConnected]);

  const refetch = () => {
    if (id && isConnected) {
      getEvent({ variables: { id, idInt: id }, fetchPolicy: "network-only" }).catch(error => console.error(error));
    }
  };

  return { error, data, loading, refetch, isConnected };
};