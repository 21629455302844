import React from "react";
import { useSchema } from "../../hooks/useSchema";

const UpdateNotification: React.FC = () => {
  const { isSchemaOutdated, loading, error } = useSchema();

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  if (isSchemaOutdated) {
    return (
      <div className="bg-opacity-80 flex w-full h-full absolute z-50 bg-black text-white align-middle justify-center items-center">
        <div>
          <h1 className="text-xl font-bold">Update Required</h1>
          <p>Please update the app to the latest version.</p>
        </div>
      </div>
    );
  }

  return null;
};

export default UpdateNotification;
