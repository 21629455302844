export const MOBILE_PAGE_CONTENT_FRAGMENT = `
      mobilePageContent {
        typeHandle
        id
        ... on mobilePageContent_headline_BlockType {
          icon
          label: text
        }
        ... on mobilePageContent_button_BlockType {
          icon
          text
          tag
          entry {
            id
            typeHandle
          }
          to
        }
        ... on mobilePageContent_subhead_BlockType {
          icon
          label: text
        }
        ... on mobilePageContent_text_BlockType {
          text
        }
        ... on mobilePageContent_image_BlockType {
          image {
            url
          }
          alt
        }
        ... on mobilePageContent_browserLink_BlockType {
          icon
          text
          website
          tag
        }
        ... on mobilePageContent_dynamicInformation_BlockType {
          options
        }
      } 
`;
