import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useNetworkStatus } from "./useNetworkStatus";

const GET_DAY = gql`
  query GetDay($entryId: [QueryArgument], $eventId: Int) {
    entry(site: "texasbaptists", id: $entryId) {
      title
      id
      startDate
      typeHandle
      children {
        title
        id
        typeHandle
        startTime
        endTime
      }
    }
    registration: entry(
      site: "texasbaptists",
      slug: "registration",
      descendantOf: $eventId
    ) {
      id
    }
    exhibitHall: entry(
      site: "texasbaptists",
      slug: "exhibit-hall",
      descendantOf: $eventId
    ) {
      id
    }
  }
`;

export const useDay = (entryId: string, eventId: string) => {
  const [getDay, { error, data, loading }] = useLazyQuery(GET_DAY, {
    variables: { eventId: parseInt(eventId), entryId },
  });
  const isConnected = useNetworkStatus();

  useEffect(() => {
    if (isConnected) {
      getDay({variables: {entryId, eventId: parseInt(eventId)}}).catch(error => console.error(error));
    }
  }, [getDay, entryId, eventId, isConnected]);

  const refetch = () => {
    if (isConnected) {
      getDay({
        variables: {entryId, eventId: parseInt(eventId)},
        fetchPolicy: "network-only",
      }).catch(error => console.error(error));
    }
  };

  return { error, data, loading, refetch, isConnected };
};
