import React from "react";
import { RouteComponentProps } from "react-router-dom";
import PeopleList from "../organisms/PeopleList";

interface MatchParams {
  eventId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const Worship: React.FC<Props> = (props) => (
  <PeopleList
    {...props}
    headlineTitle="Worship Leaders"
    icon="MicrophoneStand"
    getPersons={(entry) => entry.worship}
  />
);

export default Worship;
