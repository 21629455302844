import React from "react";
import * as icons from "../../icons";

export interface IconProps {
  icon: keyof typeof icons;
  className?: string;
}

function Icon({ icon, className }: IconProps) {
  const Component = icons[icon];
  return <Component className={className} />;
}

export default Icon