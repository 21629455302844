import React, { useEffect, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";

import { useEvent } from "../../hooks/useEvent";

import Headline from "../atoms/Headline";
import MobilePageContent from "../organisms/mobilePageContent";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonToolbar
} from "@ionic/react";
import TopNavButton from "../atoms/TopNavButton";
import ContentWrapper from "../molecules/ContentWrapper";

interface MatchParams {
  eventId: string;
}

interface EventLandingProps extends RouteComponentProps<MatchParams> {}

const EventLanding: React.FC<EventLandingProps> = ({ match }) => {
  const eventId = match.params.eventId;
  const { error, data, loading, refetch, isConnected } = useEvent(eventId);

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  const style = useMemo(() => {
    if (data && data.entry && data.entry.primary) {
      let color = data.entry.primary;
      const colorR = color.slice(1, 3);
      const colorG = color.slice(3, 5);
      const colorB = color.slice(5, 7);

      return {
        '--color-primary': `${parseInt(colorR, 16)} ${parseInt(colorG, 16)} ${parseInt(colorB, 16)}`
      };
    }
    return {};
  }, [data]);

  useEffect(() => {
    if (style['--color-primary']) {
      document.documentElement.style.setProperty('--color-primary', style['--color-primary']);
    }
  }, [style]);

  return (
      <>
        <IonHeader translucent className="ion-no-border">
          <IonToolbar style={{ "--background": "var(--ion-tab-bar-background-color)" }} className="bg-opacity-50">
            <IonButtons slot="start">
              <IonButton>
                <TopNavButton icon="ChevronLeft" route='/events' />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
          {data && (
              <>
                {data.entry?.image && (
                    <div className="bg-neutral-500 overflow-hidden -mx-6 -mt-6 mb-5 md:rounded-lg aspect-video bg-opacity-20">
                      <img
                          src={data.entry.image[0].url}
                          className="block h-full w-full aspect-video"
                          alt=""
                      />
                    </div>
                )}
                {data.entry?.title && data.entry?.mobilePageContent && (
                    <>
                      <Headline title={data.entry.title} tag={data.entry.locationOther} />
                      <MobilePageContent eventId={eventId} blocks={data.entry.mobilePageContent} />
                    </>
                )}
              </>
          )}
        </ContentWrapper>
      </>
  );
};

export default EventLanding;
