import React from 'react';
import { useHistory } from 'react-router-dom';

import { Browser } from '@capacitor/browser';

import * as icons from '../../icons';
import Icon from '../Icon';

interface ImageProps {
  srcUrl: string;
  alt?: string;
}

interface ButtonProps {
  text: string | undefined;
  tag?: string;
  icon?: keyof typeof icons;
  image?: ImageProps;
  to?: string;
  onClick?: () => void;
  openInBrowser?: string;
  small?: boolean;
  fallback?: keyof typeof icons;
  AspectVideo?: boolean;
}

const Button: React.FC<ButtonProps> = (
  {
    text,
    tag,
    image,
    to,
    onClick,
    openInBrowser,
    icon,
    small,
    fallback,
    AspectVideo
  }
) => {
  let history = useHistory();

  const handleClick = async () => {
    if (to) {
      history.push(to, 'forward');
    } else if (openInBrowser) {
      await Browser.open({ url: openInBrowser });
    } else if (onClick) {
      onClick();
    }
  };

  let textStyle = 'flex p-5 px-6 text-xl tracking-wide font-semibold'

  if (small) {
    textStyle = 'flex p-4 px-3 text-xs'
  }

  const hasValidImage = image?.srcUrl && image.srcUrl.length > 0;
  const aspectRatioClass = AspectVideo ? 'aspect-video' : 'aspect-square';
  const iconHeight = AspectVideo ? 'h-20' : 'h-32';

  if (text === 'Schedule' && !icon) {
    icon = 'ListUl';
  } else if (text === 'Worship Leaders' && !icon) {
    icon = 'MicrophoneStand';
  } else if (text === 'Speakers' && !icon) {
    icon = 'Podium';
  } else if (text === 'Maps' && !icon) {
    icon = 'Map';
  } else if (text === 'Registration' && !icon) {
    icon = 'IdCard';
  } else if (text === 'Meals & Receptions' && !icon) {
    icon = 'Utensils';
  } else if (text === 'Workshops' && !icon) {
    icon = 'ScreenUsers';
  } else if (text === 'Exhibit Hall' && !icon) {
    icon = 'Store';
  } else if (text === 'Publications' && !icon) {
    icon = 'Books';
  }

  return (
    <div onClick={handleClick} className="bg-white dark:bg-neutral-800 rounded-lg shadow-md hover:bg-neutral-100 dark:hover:bg-neutral-950 overflow-hidden mb-6 cursor-pointer -mx-1">

      {hasValidImage &&
        <div className={`${aspectRatioClass} bg-black bg-opacity-50`}>
          <img src={image.srcUrl} alt={image.alt ?? text} className="block"/>
        </div>
      }
      {fallback && !hasValidImage &&

            <div className={`w-full bg-neutral-800 bg-opacity-20 aspect-square flex align-middle justify-center ${aspectRatioClass}`}>
              <Icon icon={fallback} className={`${iconHeight} opacity-10 m-auto fill-neutral-500`} />
            </div>
      }
      <div className={textStyle}>
        {icon && icons[icon] &&
          <div className="mr-3 h-5 w-5 fill-primary text-right relative top-0.5">
            <Icon icon={icon} className="h-full w-auto block float-right" />
          </div>
        }
        <div className="flex-grow leading-tight ">
          {tag &&
            <span className="block text-xs uppercase font-medium mb-1 tracking-widest text-gray-500">
              {tag}
            </span>}
          {text}
        </div>
      </div>

    </div>
  );
};

export default Button;
