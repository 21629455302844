const DateString = (date: string, format: string = "default") => {
  const dateObj = new Date(`${date}`);
  if (isNaN(dateObj.getTime())) {
    return '';
  }

  switch (format) {
    case "default":
      return dateObj.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' });
    case "day of the week":
      return dateObj.toLocaleDateString('en-us', { weekday: 'long' });
    case "short":
      return dateObj.toLocaleDateString('en-us', { weekday: 'long', month: 'short', day: 'numeric' });
    default:
      throw new Error(`Unknown format: ${format}`);
  }
};

export default DateString;
