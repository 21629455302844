const TimeString = (startTime: string, endTime?: string, timeZone = 'local'): string => {
  const start = new Date(startTime);
  const startHour = start.getHours() % 12 || 12;
  const startMinute = start.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  const startAMPM = start.getHours() >= 12 ? 'PM' : 'AM';
  let formattedTime = `${startHour}:${startMinute} ${startAMPM}`;

  if (endTime) {
    const end = new Date(endTime);
    const endHour = end.getHours() % 12 || 12;
    const endMinute = end.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    const endAMPM = end.getHours() >= 12 ? 'PM' : 'AM';
    formattedTime = `${startHour}:${startMinute} ${startAMPM} - ${endHour}:${endMinute} ${endAMPM}`;
  }

  return formattedTime;
};

export default TimeString;
