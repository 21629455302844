import React from "react";

const Subhead = ({...props}) => {
  return(

      <h3 className='text-xl font-semibold tracking-wide mb-8'>
        {props.title}
        {props.tag &&
          <span className="block text-primary text-base tracking-widest uppercase">{props.tag}</span>
        }
      </h3>
  )
}

export default Subhead