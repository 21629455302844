import React from "react";

import Headline from "../atoms/Headline";
import Button from "../atoms/Button";
import Subhead from "../atoms/Subhead";
import TextBlock from "../atoms/TextBlock";
import Image from "../atoms/Image";
import DynamicInformation from "../atoms/DynamicInformation";

import * as icons from '../icons';
import TransparentBg from "../molecules/TransparentBg";

interface Entry {
  id: string;
  typeHandle: string;
}

interface Block {
  id: string;
  typeHandle: string;
  icon?: keyof typeof icons;
  label: string;
  to?: string;
  text?: string | undefined;
  image?: { url: string }[];
  alt?: string;
  website?:string;
  options?:string | undefined;
  entry?: Entry[];
  tag?: string;
}

interface MobilePageContentProps {
  blocks: Block[];
  eventId: string;
}

const MobilePageContent: React.FC<MobilePageContentProps> = ({ blocks, eventId }) => {

  return (
    <>
      {blocks.map((block: Block) => {
        switch (block.typeHandle) {
          case "headline":
            return <Headline title={block.label} key={block.id} icon={block.icon} />;
          case "subhead":
            return <Subhead title={block.label} key={block.id} />;
          case "text":
            return (
              <TransparentBg>
                <TextBlock text={{ __html: block.text || "" }} key={block.id} />
              </TransparentBg>
            );
          case "button":

            if (block.entry && block.entry.length > 0 && block.entry[0].id) {
              return(
                <Button
                  text={block.text}
                  icon={block.icon}
                  to={`/events/${eventId}/details/${block.entry[0].typeHandle}/${block.entry[0].id}`}
                  key={block.id}
                />
              )
            }

            return (
              <Button
                text={block.text}
                icon={block.icon}
                to={`/events/${eventId}/details/${block.to}`}
                key={block.id}
              />
            );

          case "image":
            if (block.image && block.image.length > 0) {
              return (
                <Image
                  url={block.image[0].url}
                  alt={block.alt}
                  key={block.id}
                />
              );
            }
            return null;
          case "browserLink":
            return (
              <Button
                text={block.text}
                tag={block.tag}
                icon={block.icon}
                key={block.id}
                openInBrowser={block.website}
              />
            );
          case "dynamicInformation":
            return (
              <DynamicInformation key={block.id} option={block.options} eventId={eventId} />
            );
          default:
            return null;
        }
      })}
    </>
  );
};

export default MobilePageContent;
