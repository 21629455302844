import {gql, useLazyQuery} from "@apollo/client";
import {useEffect} from "react";
import { useNetworkStatus } from "./useNetworkStatus";

const GET_MEAL = gql`
  query GetMeal( $id: [QueryArgument] ) {
    entry( site: "texasbaptists", id: $id ) {
      ... on schedule_mealReception_Entry {
        title
        id
        startTime
        endTime
        venue
        descriptionRichText
        parent {
          ... on schedule_activity_Entry {
            id
            startTime
            endTime
            parent {
              ...on schedule_day_Entry {
                id
                startDate
              }
            }
          }
          ... on schedule_day_Entry {
            id
            startDate
          }
        }
      }
    }
  }
`

export const useMeal = (id:string) => {
  const [getMeal, { error, data, loading }] = useLazyQuery(GET_MEAL, {
    variables: { id }
  });
  const isConnected = useNetworkStatus();

  useEffect(() => {
    if (isConnected) {
      getMeal({ variables: { id } }).catch(error => console.error(error));
    }
  }, [getMeal, id, isConnected]);

  const refetch = () => {
    if (isConnected) {
      getMeal({ variables: { id }, fetchPolicy: "network-only" }).catch(error => console.error(error));
    }
  };

  return { error, data, loading, refetch, isConnected }
}