import React from "react";

import EventList from "../organisms/EventList";

interface MatchParams {
  eventId: string;
}

interface Props {
  match: {
    params: MatchParams;
  };
}

const Meals: React.FC<Props> = ({ match }) => {
  const { eventId } = match.params;

  return (
    <EventList
      eventId={eventId}
      headline="Workshops"
      icon="ScreenUsers"
      eventKey="workshops"
      buttonPath="workshop"
      tagData="shortname"
    />
  );
};

export default Meals;
