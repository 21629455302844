import {gql, useLazyQuery} from "@apollo/client";
import {useEffect} from "react";
import { useNetworkStatus } from "./useNetworkStatus";

const GET_ACTIVITY = gql`
  query GetActivity( $id: [QueryArgument] ) {
    entry( site: "texasbaptists", id: $id ) {
      title
      id
      startTime
      endTime
      descriptionRichText
      icon: text
      children {
        title
        id
        typeHandle
        ... on schedule_workshop_Entry {
          shortname
        }
        ... on schedule_mealReception_Entry {
          venue
        }
      }
      parent {
        title
        id
        startDate
      }
    }
  }
`

export const useActivity = (id:string) => {
  const [getActivity, { error, data, loading }] = useLazyQuery(GET_ACTIVITY, {
    variables: { id }
  });
  const isConnected = useNetworkStatus();

  useEffect(() => {
    if (isConnected) {
      getActivity({variables: {id}}).catch(error => console.error(error));
    }
  }, [getActivity, id, isConnected]);

  const refetch = () => {
    if (isConnected) {
      getActivity({variables: {id}, fetchPolicy: "network-only"}).catch(error => console.error(error));
    }
  };

  return { error, data, loading, refetch, isConnected }
}