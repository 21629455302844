import {gql, useLazyQuery} from "@apollo/client";
import {useEffect} from "react";
import { useNetworkStatus } from "./useNetworkStatus";

const GET_WORKSHOP = gql`
  query GetWorkshop( $id: [QueryArgument] ) {
    entry( site: "texasbaptists", id: $id ) {
      title
      id
      typeHandle
      descriptionRichText
      description
      shortname
      venue
      parent {
        id
        typeHandle
        startTime
        endTime
        parent {
          id
          typeHandle 
          startDate
        }
      }
    }
  }
`

export const useWorkshop = (id:string) => {
  const [getWorkshop, { error, data, loading }] = useLazyQuery(GET_WORKSHOP, {
    variables: { id }
  });
  const isConnected = useNetworkStatus();

  useEffect(() => {
    if (isConnected) {
      getWorkshop({ variables: { id } }).catch(error => console.error(error));
    }
  }, [getWorkshop, id, isConnected]);

  const refetch = () => {
    if (isConnected) {
      getWorkshop({ variables: { id }, fetchPolicy: "network-only" }).catch(error => console.error(error));
    }
  };

  return { error, data, loading, refetch, isConnected }
}