import React from "react";
import TextBlock from "../atoms/TextBlock";
import Image from "../atoms/Image";

interface Block {
  id: string;
  typeHandle: string;
  paragraphText?: string | undefined;
  image?: { url: string }[];
  alt?: string;
  code?: string | undefined;
}

interface ArticleContentProps {
  blocks: Block[];
  articleId: string;
}

const ArticleContent: React.FC<ArticleContentProps> = ({ blocks, articleId }) => {

  return (
    <>
      {blocks.map((block: Block) => {
        switch (block.typeHandle) {

          case "text":
            return (
              <TextBlock text={{ __html: block.paragraphText || "" }} key={block.id} />
            );

          case "articleImage":
            if (block.image && block.image.length > 0) {
              return (
                <Image
                  url={block.image[0].url}
                  alt={block.alt}
                  key={block.id}
                />
              );
            }
            return null;

          case "code":
            return null;

          default:
            // console.log(block.typeHandle);
            return null;
        }
      })}
    </>
  );
};

export default ArticleContent;
