import React from "react";

import { usePerson } from "../../hooks/usePerson";

import Headline from "../atoms/Headline";
import TextBlock from "../atoms/TextBlock";

import ContentWrapper from "../molecules/ContentWrapper";
import TransparentBg from "../molecules/TransparentBg";

const Person = ({...props}) => {

  const { entryId } = props.match.params
  const {error, data, loading, refetch, isConnected} = usePerson(entryId);

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  let html = {__html :  data?.entry?.descriptionRichText}

  return(
    <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
      { data &&
        <>

          {data.entry?.profilePhoto[0]?.url &&
            <img
              src={data.entry.profilePhoto[0]?.url}
              alt=""
              className="box overflow-hidden rounded-lg mb-8"
            />
          }

          <Headline
            title={data.entry.fullName ?? data.entry.title}
          />

          <TransparentBg>
            <TextBlock
              text={html}
            />
          </TransparentBg>

        </>
      }
    </ContentWrapper>
  )
}

export default Person
