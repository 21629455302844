import { Network } from '@capacitor/network';
import { useEffect, useState } from "react";

export const useNetworkStatus = () => {
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    const checkNetwork = async () => {
      const status = await Network.getStatus();
      setIsConnected(status.connected);
    };

    checkNetwork().catch(error => console.error(error));

    let handler = Network.addListener('networkStatusChange', (status) => {
      setIsConnected(status.connected);
    });

    return () => {
      const removeListener = async () => {
        await handler.remove();
      };
      removeListener().catch(error => console.error(error));
    }
  }, []);

  return isConnected;
};
