import { useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useNetworkStatus } from "./useNetworkStatus";

const GET_ARTICLE = gql`
    query GetArticle($id: [QueryArgument]) {
        entry(site: "texasbaptists", id: $id, type: "articlev1") {
            title
            id
            articleAuthor
            teaser
            featuredImage {
                url @transform(width: 1600, height: 900, mode: "crop")
            }
            articleBuilderV1 {
                typeHandle
                id
                ... on articleBuilderV1_text_BlockType {
                    paragraphText
                }
                ... on articleBuilderV1_code_BlockType {
                    code
                }
                ... on articleBuilderV1_articleImage_BlockType {
                    image {
                        url
                    }
                }
            }
        }
    }
`;

interface ArticleResult {
  error: any;
  data: any;
  loading: boolean;
  refetch: () => void;
  isConnected: boolean;
}

export const useArticle = (idString: string): ArticleResult => {
  const id = parseInt(idString);
  const [getArticle, { error, data, loading }] = useLazyQuery(GET_ARTICLE);
  const isConnected = useNetworkStatus();

  useEffect(() => {
    if (id && isConnected) {
      getArticle({ variables: { id, idInt: id } }).catch(error => console.error(error));
    }
  }, [getArticle, id, isConnected]);

  const refetch = () => {
    if (id && isConnected) {
      getArticle({ variables: { id, idInt: id }, fetchPolicy: "network-only" }).catch(error => console.error(error));
    }
  };

  return { error, data, loading, refetch, isConnected };
};
