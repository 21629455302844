import React from "react";
import {useDetails} from "../../hooks/useDetails";
import MobilePageContent from "../organisms/mobilePageContent";
import ContentWrapper from "../molecules/ContentWrapper";

const Details = ({...props}) => {

  const { entryId, eventId } = props.match.params
  const {error, data, loading, refetch, isConnected} = useDetails(entryId)
  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  return(
    <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
      { data && <MobilePageContent
        eventId={eventId}
        blocks={data.entry.mobilePageContent} /> }
    </ContentWrapper>
  )

}

export default Details