import React from 'react';
import { useEvents } from "../../hooks/useEvents";
import {IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {RouteComponentProps} from "react-router-dom";
import Button from "../atoms/Button";
import ContentWrapper from "../molecules/ContentWrapper";

interface  Entry {
  id: string;
  title: string;
  locationOther: string;
  image?: {
    srcUrl: string;
    alt?:string;
  }[];
}

const Events: React.FC<RouteComponentProps> = () => {
  const { error, data, loading, refetch, isConnected } = useEvents();

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  return (
    <IonPage>
      <IonHeader translucent className="ion-no-border" >
        <IonToolbar style={{"--background":"var(--ion-tab-bar-background-color)"}} className="bg-opacity-50">
          <IonTitle>Events</IonTitle>
        </IonToolbar>
      </IonHeader>
      <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
        {data && (
          <>
            {data.entries.map((event: Entry) => (
              <React.Fragment key={event.id}>
                <>
                  {event.image &&
                    <Button to={`/events/${event.id}`} text={event.title} tag={event.locationOther} image={event.image[0]} AspectVideo />
                  }
                  {!event.image &&
                    <Button to={`/events/${event.id}`} text={event.title} tag={event.locationOther} fallback='CalendarLines' AspectVideo />
                  }
                </>
              </React.Fragment>
            ))}
          </>
        )}
      </ContentWrapper>
    </IonPage>
  );
};

export default Events;
