import React from "react";

import {useSession} from "../../hooks/useSession";

import Headline from "../atoms/Headline";
import TextBlock from "../atoms/TextBlock";
import Button from "../atoms/Button";
import Subhead from "../atoms/Subhead";

import ContentWrapper from "../molecules/ContentWrapper";

import timeString from "../utilities/TimeString";
import dateString from "../utilities/DateString";
import TransparentBg from "../molecules/TransparentBg";

const Session = ({...props}) => {

  const { entryId, eventId } = props.match.params

  const {error, data, loading, refetch, isConnected} = useSession(entryId);

  let html = {__html :  data?.entry?.descriptionRichText}

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  return(
    <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
      { data &&
        <>
          <Headline
            icon={data.entry.icon}
            title={data.entry.title}
          />

          <Subhead
            title={dateString(data.entry.parent.startDate, "short")}
            tag={timeString(data.entry.startTime, data.entry.endTime)}
          />

          <TextBlock
            text={html}
          />

          <div className="-mx-8">
              <div className="flex overflow-x-auto snap-x px-8 swiper-scrollbar-drag">
                <div className="w-6 flex-shrink-0 h-420 snap-start"></div>
                {data.entry.agenda.map((item: {
                  label: string,
                  presenter: string,
                  about: string,
                  id: string,
                  link: Array<{
                    title: string;
                    id: string;
                    profilePhoto: Array<{url: string|undefined;}>;
                  }>;
                }) => {
                  if (item?.link?.[0]?.title && item.link[0]?.id && item.link[0]?.profilePhoto?.[0]?.url) {

                    return (
                      <div className="w-40 pl-4 flex-shrink-0 snap-start ">
                        <Button
                          text={item.link[0].title}
                          small={true}
                          image={
                            {
                              srcUrl: item.link[0].profilePhoto[0].url,
                              alt: item.link[0].title
                            }
                          }
                          to={`/events/${eventId}/details/person/${item.link[0].id}`}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>

          </div>

          {data.entry.agenda.length !== 0 && (

            <TransparentBg>
            {data.entry.agenda.map( (item: {
              label: string,
              presenter: string,
              about: string,
              id: string
            }) => {

              return(
                <div key={item.id}>
                  <div className="text-sm mb-6">
                    <h2 className="text-lg font-semibold tracking-wide">{item.label}</h2>
                    <p>{item.presenter}</p>
                    {item.about &&
                      <p>{item.about}</p>
                    }
                  </div>
                </div>
              )
            })}
          </TransparentBg>
            )}
        </>
      }
    </ContentWrapper>
  )
}

export default Session