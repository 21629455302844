import React from "react";
import {Route, RouteComponentProps} from "react-router-dom";

import {IonPage, IonRouterOutlet} from "@ionic/react";
import EventLanding from "./EventLanding";
import EventDetails from "./EventDetails";

interface MatchParams {
  eventId: string;
}

interface EventProps extends RouteComponentProps<MatchParams> {}

const Event: React.FC<EventProps> = ({ match }) => {

  return (
    <IonPage>
      <IonRouterOutlet>
        <Route path={match.path} component={EventLanding} exact={true} />
        <Route path={`${match.path}/details`} component={EventDetails} />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default Event;
