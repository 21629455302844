import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { IonPage, IonRouterOutlet } from "@ionic/react";
import ArticleLanding from "./ArticleLanding";

interface MatchParams {
  articleId: string;
}

interface ArticleProps extends RouteComponentProps<MatchParams> {}

const Article: React.FC<ArticleProps> = ({ match }) => {

  return (
    <IonPage>
      <IonRouterOutlet>
        <Route path={match.path} component={ArticleLanding} exact={true} />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default Article;
