import React from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { IonButton, IonButtons, IonHeader, IonToolbar } from "@ionic/react";
import Headline from "../atoms/Headline";
import TopNavButton from "../atoms/TopNavButton";
import ArticleWrapper from "../molecules/ArticleWrapper";
import ArticleContent from "../organisms/articleContent";
import { useArticle } from "../../hooks/useArticle";

interface MatchParams {
  articleId: string;
}

interface ArticleLandingProps extends RouteComponentProps<MatchParams> {}

const ArticleLanding: React.FC<ArticleLandingProps> = ({ match }) => {
  const articleId = match.params.articleId;
  const { error, data, loading, refetch, isConnected } = useArticle(articleId);

  const handleRefresh = async (article: CustomEvent) => {
    await refetch();
    article.detail.complete();
  };

  let style: { [key: string]: string } = {}

  if (data && data.entry) {
    let color = "#00b140";
    const colorR = color.slice(1,3);
    const colorG = color.slice(3,5);
    const colorB = color.slice(5,7);

    style = {
      '--color-primary': `${parseInt(colorR, 16)} ${parseInt(colorG, 16)} ${parseInt(colorB, 16)}`
    };
  }

  useEffect(() => {
    if (style['--color-primary']) {
      document.documentElement.style.setProperty('--color-primary', style['--color-primary']);
    }
  }, [style]);

  return (
    <>
      <IonHeader translucent className="ion-no-border" >
        <IonToolbar style={{"--background":"var(--ion-tab-bar-background-color)"}} className="bg-opacity-50">
          <IonButtons slot="start">
            <IonButton>
              <TopNavButton icon="ChevronLeft" route='/news' />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <ArticleWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
        {data && (
          <>
            {data && data.entry && data.entry.featuredImage && (
              <div className="bg-neutral-500 overflow-hidden -mx-6 -mt-6 mb-5 md:rounded-lg aspect-video bg-opacity-20">
                <img
                  src={data.entry.featuredImage[0].url}
                  className="block h-full w-full aspect-video "
                  alt=""
                />
              </div>
            )}
            {data && data.entry && data.entry.title && data.entry.articleBuilderV1 && (
              <>
                <Headline title={data.entry.title} tag={data.entry.articleAuthor} />
                <ArticleContent articleId={articleId} blocks={data.entry.articleBuilderV1} />
              </>
            )}
          </>
        )}
      </ArticleWrapper>
    </>
  );
};

export default ArticleLanding;
