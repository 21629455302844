import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

// GraphQL query to fetch text from Craft CMS
const GET_SCHEMA_TEXT = gql`
    query GetSchemaText {
        entries(site: "texasbaptists", section: "settings", limit: 1) {
            text
        }
    }
`;

const appSchema = 1;

export const useSchema = () => {
    const { data, loading, error } = useQuery(GET_SCHEMA_TEXT);
    const [isSchemaOutdated, setIsSchemaOutdated] = useState(false);

    useEffect(() => {
        if (data && data.entries && data.entries.length > 0) {
            const fetchedSchema = data.entries[0].text;

            if (fetchedSchema > appSchema) {
                setIsSchemaOutdated(true);
            }
        }
    }, [data]);

    return { isSchemaOutdated, loading, error };
};
