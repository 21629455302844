import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { useActivity } from "../../hooks/useActivity";

import Button from "../atoms/Button";
import Headline from "../atoms/Headline";
import Subhead from "../atoms/Subhead";

import ContentWrapper from "../molecules/ContentWrapper";

import DateString from "../utilities/DateString";
import timeString from "../utilities/TimeString";

import * as icons from '../icons'
import TransparentBg from "../molecules/TransparentBg";
import TextBlock from "../atoms/TextBlock";

interface Child {
  title: string;
  typeHandle: string;
  id: string;
  shortname?: string;
  venue?: string;
  icon?: keyof typeof icons;
}

interface MatchParams {
  entryId: string;
  eventId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const Activity: React.FC<Props> = ({ match }) => {
  const { entryId, eventId } = match.params;
  const { error = null, data = null, loading = true, refetch, isConnected } = useActivity(entryId);
  const { entry } = data ?? {};

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  let html = {__html :  entry?.descriptionRichText}

  return (
    <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
      {data && (
        <>
          <Headline title={entry?.title} icon={entry.icon} />
          <Subhead title={DateString(entry.parent.startDate)} tag={timeString(entry.startTime, entry.endTime)} />

          {entry?.descriptionRichText && (
            <TransparentBg>
              <TextBlock
                text={html}
              />
            </TransparentBg>
          )}

          {entry?.children?.map((child: Child) => {

            if (child.typeHandle === "workshop") {
              return (
                <Button
                  key={child.id}
                  to={`/events/${eventId}/details/${child.typeHandle}/${child.id}`}
                  text={child.title}
                  tag={child.shortname}
                />
              );

            } else if (child.typeHandle === "mealReception") {

              return (
                <Button
                  key={child.id}
                  to={`/events/${eventId}/details/${child.typeHandle}/${child.id}`}
                  text={child.title}
                  tag={child.venue}
                />
              );

            } else {

              return (
                <Button
                  key={child.id}
                  to={`/events/${eventId}/details/${child.typeHandle}/${child.id}`}
                  text={child.title}
                />
              )

            }
          })}
        </>
      )}
    </ContentWrapper>
  );
};

export default Activity;
