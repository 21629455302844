import { useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useNetworkStatus } from "./useNetworkStatus";

const GET_EVENTS = gql`
  query GetEvents {
    entries(site: "texasbaptists", yesNo1: true, type: "event") {
      title
      id
      locationOther
      image {
        srcUrl: url @transform(width: 1600, height: 900, mode: "crop")
      }
    }
  }
`;

interface EventsResult {
  error: any;
  data: any;
  loading: boolean;
  refetch: () => void;
  isConnected: boolean;
}

export const useEvents = (): EventsResult => {
  const [getEvents, { error, data, loading }] = useLazyQuery(GET_EVENTS);
  const isConnected = useNetworkStatus();

  useEffect(() => {
    if (isConnected) {
      getEvents().catch(error => console.error(error));
    }
  }, [getEvents, isConnected]);

  const refetch = () => {
    if (isConnected) {
      getEvents({ fetchPolicy: "network-only" }).catch(error => console.error(error));
    }
  };

  return { error, data, loading, refetch, isConnected };
};
