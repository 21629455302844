import React from "react";
import { useWorkshop } from "../../hooks/useWorkshop";

import Headline from "../atoms/Headline";
import TextBlock from "../atoms/TextBlock";
import Subhead from "../atoms/Subhead";

import ContentWrapper from "../molecules/ContentWrapper";

import dateString from "../utilities/DateString";
import timeString from "../utilities/TimeString";
import TransparentBg from "../molecules/TransparentBg";

interface Props {
  match: {
    params: {
      entryId: string;
    };
  };
}

const Workshop: React.FC<Props> = ({ match }) => {

  const { entryId } = match.params;
  const { error, data, loading, refetch, isConnected } = useWorkshop(entryId);

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  let html = { __html: data?.entry?.descriptionRichText };

  return (
    <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
      {data && (
        <>
          <Headline icon="ScreenUsers" title={data.entry.title} />

          <Subhead
            title={dateString(data.entry.parent.parent.startDate, "short")}
            tag={timeString(data.entry.parent.startTime, data.entry.parent.endTime)}
          />

          <TransparentBg>
            <TextBlock text={html} />
            <div className="text-block">
              <p>
                Presented by {data.entry.shortname}
              </p>
              {data.entry.venue && (
                <p>Location: {data.entry.venue}</p>
              )}
            </div>
          </TransparentBg>
        </>
      )}
    </ContentWrapper>
  );
};

export default Workshop;
