import {gql, useLazyQuery} from "@apollo/client";
import {useEffect} from "react";
import { useNetworkStatus } from "./useNetworkStatus";

const GET_SESSION = gql`
  query GetSession( $id: [QueryArgument] ) {
    entry( site: "texasbaptists", id: $id ) {
    id
    title
    typeHandle
      ... on schedule_businessWorship_Entry {
        title
        startTime
        endTime
        descriptionRichText
        icon: text
        parent {
          startDate
        }
        agenda {
          ...on agenda_item_BlockType {
            label
            presenter
            about
            id
            link: entry {
              id
              title
              profilePhoto {
                url @transform(width: 1600, height: 1600, mode: "crop")
              }
            }
          }
        }
      }
    }
  }
`

export const useSession = (id:string) => {
  const [getSession, { error, data, loading }] = useLazyQuery(GET_SESSION, {
    variables: { id }
  });
  const isConnected = useNetworkStatus();

  useEffect(() => {
    if (isConnected) {
      getSession({ variables: { id } }).catch(error => console.error(error));
    }
  }, [getSession, id, isConnected]);

  const refetch = () => {
    if (isConnected) {
      getSession({variables: {id}, fetchPolicy: "network-only"}).catch(error => console.error(error));
    }
  };

  return { error, data, loading, refetch, isConnected }
}