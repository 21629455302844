import React from "react";

import { useMeal } from "../../hooks/useMeal";

import Headline from "../atoms/Headline";
import Subhead from "../atoms/Subhead";
import TextBlock from "../atoms/TextBlock";

import ContentWrapper from "../molecules/ContentWrapper";

import DateString from "../utilities/DateString";
import TimeString from "../utilities/TimeString";
import TransparentBg from "../molecules/TransparentBg";

const Meal = ({ match }: any) => {
  const { entryId } = match.params;
  const { error, data, loading, refetch, isConnected } = useMeal(entryId);

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  const mealInfo = {
    day: data?.entry?.parent?.startDate || data?.entry?.parent?.parent?.startDate || false,
    startTime: data?.entry?.startTime || data?.entry?.parent?.startTime || false,
    endTime: data?.entry?.endTime || data?.entry?.parent?.endTime || false,
  };

  const html = { __html: data?.entry?.descriptionRichText };

  return (
    <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
      {data && (
        <>
          <Headline title={data.entry.title} icon="Utensils" tag={data.entry.venue} />
          <Subhead title={DateString(mealInfo.day)} tag={TimeString(mealInfo.startTime, mealInfo.endTime)} />

          {data?.entry?.descriptionRichText &&
            <TransparentBg>
              <TextBlock text={html} />
            </TransparentBg>
          }

        </>
      )}
    </ContentWrapper>
  );
};

export default Meal;
