import React from "react";
import { useEvent } from "../../../hooks/useEvent";
import DateString from "../../utilities/DateString";
import TimeString from "../../utilities/TimeString";

interface Day {
  id: string;
  startDate: any;
  children: {
    startTime: string;
    endTime: string;
    typeHandle: string;
    id: string;
  }[];
}

export interface TimeListProps {
  eventId: string;
  dataKey: string;
  headerText: string;
}

const TimeList: React.FC<TimeListProps> = ({ eventId, dataKey, headerText }) => {
  const { data } = useEvent(eventId);

  return (
    <>
      {data &&
        <div className="my-12">
          <h3 className="text-lg font-semibold tracking-widest mb-2 text-primary uppercase">{headerText}</h3>
          {data[dataKey].map((day: Day) => {
            const { id, startDate, children } = day;

            return (
              <div key={id} className="mb-4">
                {children.length !== 0 &&
                  <p className="font-semibold tracking-wide">{DateString(startDate, "short")}</p>
                }
                {children.map((time) => {
                  const { startTime, endTime, typeHandle, id } = time;
                  if (typeHandle) {
                    return <p key={id} className="text-sm uppercase tracking-wider font-medium">{TimeString(startTime, endTime)}</p>
                  }
                  return null;
                })}
              </div>
            )
          })}
        </div>
      }
    </>
  )
}

export default TimeList;
