import React from 'react';

interface TransparentBgProps {
  children: React.ReactNode;
}

const TransparentBg: React.FC<TransparentBgProps> = ({ children }) => {
  return (
    <div className="-mx-6 p-6 relative">
      <div className="w-full h-full object-cover absolute top-0 left-0 bg-gradient-to-b from-white dark:from-black z-0 opacity-50 from-75%"></div>
      <div className="relative z-10">{children}</div>
    </div>
  );
};

export default TransparentBg;