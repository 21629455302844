import {gql, useLazyQuery} from "@apollo/client";
import {useEffect} from "react";
import { useNetworkStatus } from "./useNetworkStatus";

const GET_PERSON = gql`
  query GetPerson( $id: [QueryArgument] ) {
    entry( site: "texasbaptists", id: $id ) {
      title
      ... on people_staff_Entry {
        fullName
      }
      id
      typeHandle
      descriptionRichText
      profilePhoto {
        url @transform(width: 1600, height: 1600, mode: "crop")
      }
    }
  }
`

export const usePerson = (id:string) => {
  const [getPerson, { error, data, loading }] = useLazyQuery(GET_PERSON, {
    variables: { id }
  });
  const isConnected = useNetworkStatus();

  useEffect(() => {
    if (isConnected) {
      getPerson({ variables: { id } }).catch(error => console.error(error));
    }
  }, [getPerson, id, isConnected]);

  const refetch = () => {
    if (isConnected) {
      getPerson({ variables: { id }, fetchPolicy: "network-only" }).catch(error => console.error(error));
    }
  };

  return { error, data, loading, refetch, isConnected }
}