import React from "react";
import {IonSpinner} from "@ionic/react";

const Loading = () => {
  return(
    <div className="text-center flex justify-center mt-4">
      <IonSpinner></IonSpinner>
      <span className="block pl-2 text-gray-600">Loading...</span>
    </div>
  )
}

export default Loading