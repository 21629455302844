import React from "react";
import { useDay } from "../../hooks/useDay";
import TimeString from "../utilities/TimeString";
import { RouteComponentProps } from "react-router-dom";
import Button from "../atoms/Button";
import Headline from "../atoms/Headline";
import ContentWrapper from "../molecules/ContentWrapper";

interface Entry {
  typeHandle: string;
  id: number;
  startTime: string;
  endTime: string;
  title: string;
  children: Entry[];
}

const getEntryLink = (entry: Entry, eventId: string, registration: string, exhibitHall: string): string => {
  switch (entry.typeHandle) {
    case "activity":
      return `/events/${eventId}/details/activity/${entry.id}`;
    case "exhibitHall":
      return `/events/${eventId}/details/details/${exhibitHall}`;
    case "registration":
      return `/events/${eventId}/details/details/${registration}`;
    case "businessWorship":
      return `/events/${eventId}/details/session/${entry.id}`;
    case "mealReception":
      return `/events/${eventId}/details/mealReception/${entry.id}`;
    default:
      return "";
  }
};

interface MatchParams {
  entryId: string;
  eventId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const Day: React.FC<Props> = ({ match }) => {
  const { entryId, eventId } = match.params;
  const { error, data, loading, refetch, isConnected } = useDay(entryId, eventId);
  const { entry, registration, exhibitHall } = data || {};

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  let day = "";

  if (entry && entry.startDate) {
    day = new Date(entry.startDate).toLocaleDateString("en-us", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  const shouldShowButton = (entry: Entry) => {
    if (entry.typeHandle === "registration" && !registration?.id) {
      return false;
    }

    if (entry.typeHandle === "exhibitHall" && !exhibitHall?.id) {
      return false;
    }

    return true;
  };

  return (
    <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
      {data && (
        <>
          <Headline title={entry.title} icon="Calendar" tag={day} />

          {entry.children.map((entry: Entry, index: number) => (
            <div key={index}>
              {shouldShowButton(entry) && (
                <Button
                  to={getEntryLink(entry, eventId, registration?.id, exhibitHall?.id)}
                  tag={TimeString(entry.startTime, entry.endTime)}
                  text={entry.title}
                />
              )}
            </div>
          ))}
        </>
      )}
    </ContentWrapper>
  );
};

export default Day;
