import React from "react";
import * as icons from "../../icons";
import Icon from "../Icon";

type HeadlineProps = {
  title: string;
  tag?: string;
  icon?: keyof typeof icons;
  className?: string;
};

const Headline: React.FC<HeadlineProps> = ({ tag, className, title, icon }) => {
  return (
    <h2 className={`${className} text-2xl sm:text-3xl font-semibold mb-8 flex`}>
        {icon && icons[icon] &&
          <div className="h-5 sm:h-6 sm:w-6 mr-2 sm:mr-3 top-0.5 relative flex-shrink-0">
          <Icon icon={icon} className="fill-primary h-full  block"/>
          </div>
          }

      <div className="flex-grow">
        <span className="leading-6 block">{title}</span>
        {tag && <span className="block text-sm uppercase text-primary font-medium tracking-widest mt-2">{tag}</span> }
      </div>
    </h2>
  );
};

export default Headline;
